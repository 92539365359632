/* NOTE: the page colors are setted in the main css file */

#festival_page * {
  font-family: 'biancoenero', sans-serif;
  font-weight: bold;
  font-style: normal;
  color: var(--festival-text-color) !important;
}

.font_family {
  font-family: 'biancoenero', sans-serif;
}

#festival_page #initial_logo * {
  fill: var(--festival-main-color);
}

#festival_page #initial_logo {
  width: 30vw;
  height: 30vh;
}

#festival_page .countdown_container {
  display: grid;
  height: 100vh;
  place-content: center;
  text-align: center;
  background: var(--festival-main-color);
  background: radial-gradient(circle, var(--festival-main-color) 25%, var(--festival-third-color) 100%);
  background-size: 200% 200%;
  animation-name: background_movement;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

#festival_page .countdown_container * {
  color: var(--festival-second-color);
}

#festival_page .countdown_container .sentence {
  font-size: calc(20px + 1vw);
}

#festival_page .countdown_container .time {
  font-size: calc(40px + 1vw);
}

#festival_page .first_sentence_container {
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(180deg, var(--festival-main-color) 100vh, var(--festival-second-color) 100vh, var(--festival-second-color) 200vh, var(--festival-main-color) 200vh);
  background-size: 100% 300vh;
  animation-name: change_color;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

#festival_page .first_sentence {
  text-align: center;
  padding: 20px;
  font-size: 95px;
  font-style: normal;
  font-weight: 700;
  line-height: 109px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px var(--festival-main-color);
}

#festival_page .festival_content {
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  animation-name: fade_in;
  animation-duration: 0.5s;
}

#festival_page .presentation {
  background-color: var(--festival-second-color);
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 20px;
  border-radius: 40px;
  min-height: 90vh;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  /* max-width: min-content; */
}

#festival_page .presentation .inline {
  display: grid;
  max-width: 100%;
  max-height: 160px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  place-items: center;
  grid-auto-columns: 1fr 7fr;
  grid-auto-flow: column;
}

#festival_page .presentation .inline img{
  max-height: 160px;
}

#festival_page .presentation .carousel_container {
  display: grid;
  width: 100%;
  max-height: 150px;
  padding-left: 10px;
  align-content: center;
}

#festival_page .presentation .carousel_container img {
  max-height: 100px;
}

#festival_page .presentation .carousel_container .carousel_item {
  /* display: grid; */
}

#festival_page .presentation .carousel_container .carousel_item img {
  /* width: 100px; */
  /* padding: 10px; */
}

#festival_page .presentation .content_container {
  height: 100%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  place-content: center;
  align-content: center;
}

#festival_page .presentation .logo {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  height: fit-content;
  background-color: var(--festival-main-color);
  border-radius: 12px;
  /* box-shadow: 16px 29px 44px -10px rgba(0, 0, 0, 0.25); */
}

#festival_page .presentation .festival_name {
  color: var(--festival-text-color);
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 109px; /* 109% */
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 4px var(--festival-text-color);
}

#festival_page .presentation .festival_description {
  color: #DBDBDB;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#festival_page .presentation .festival_description {
  color: #DBDBDB;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#festival_page .presentation .host_container .host_title {
  color: var(--festival-main-color);
}


#festival_page .presentation .subscribe_button {
  background-color: var(--festival-main-color);
  color: var(--festival-third-color);
  box-shadow: unset;
}

#festival_page .program {
  margin-top: 5vh;
  min-height: 90vh;
}

#festival_page .activity_card * {
  color: #464646;
}

#festival_page .activity_card .title {
  font-weight: 700;
}

#festival_page .activity_card .description {
  font-weight: 200;
  font-size: 18px;
}

#festival_page .tab_view {
  background: transparent;
}

#festival_page .header_title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px var(--festival-third-color);
  font-size: 45px;
  font-weight: 700;
}

#festival_page .scrollable_program {
  height: calc(90vh - 74px);
  overflow-y: scroll;
  padding-top: 10px;
}

#festival_page .scrollable_program::-webkit-scrollbar-thumb {
  background: var(--festival-second-color);
  display: block;
}

#festival_page .homepage_link {
  margin-top: 12px;
  text-align: center;
  text-decoration: underline;
  color: var(--festival-main-color);
}

.open_ticket_button {
  background-color: var(--festival-main-color) !important;
  color: var(--festival-second-color);
  box-shadow: unset;
  width: 100%;
}

/* tabview */

#festival_page .p-tabview .p-tabview-nav,
#festival_page .p-tabview .p-tabview-nav-btn.p-link {
  background: var(--festival-main-color);
}

#festival_page .p-tabview .p-tabview-nav-btn.p-link,
#festival_page .p-tabview .p-tabview-nav-btn.p-link:focus {
  box-shadow: unset;
}

#festival_page .p-timeline-left .p-timeline-event-opposite{
  display: none;
}

#festival_page .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--festival-second-color);
}

/* timeline */

#festival_page .p-timeline .p-timeline-event-marker,
#festival_page .p-timeline .p-timeline-event-connector {
  background-color: var(--festival-third-color);
  border-color: var(--festival-third-color);
}

#festival_page .p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
  margin-top: -8px;
}

#festival_page .p-tabview .p-tabview-panels {
  margin-top: 8px;
}

/* Chip */
#festival_page .MuiChip-colorInfo {
  background-color: var(--festival-main-color);
  color: var(--festival-second-color);
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Inplace */
#festival_page .p-inplace-display {
  padding-left: 0;
  text-decoration: underline;
  font-weight: 700;
}

#festival_page .p-inplace-display:hover {
  background: transparent;
}

#festival_page .p-inplace-display:focus {
  box-shadow: unset;
}

#festival_page .p-button.p-component.p-inplace-content-close.p-button-icon-only {
  background: transparent;
  border: unset;
  box-shadow: unset;
  padding: 0;
  width: auto;
  text-decoration: underline;
  font-weight: 700;
}

/* scrollpanel */
#festival_page .p-scrollpanel .p-scrollpanel-bar{
  background: var(--festival-second-color);
}

/* Sponsor carousel */

#festival_page .carousel.slide,
#festival_page .carousel-inner,
#festival_page .carousel-item ,
#festival_page .carousel_container .row{
  height: 100%;
}

/* Program carousel */
#festival_page .p-carousel-prev,
#festival_page .p-carousel-next {
  width: 20px;
  height: 20px;
}

#festival_page .p-scrollpanel-content {
  width: 100% !important;
}

@keyframes change_color {
  0% {
    background-position: 0% 300vh;
  } 
  40% {
    background-position: 0% 200vh;
  }
  75% {
    background-position: 0% 200vh;
  }
  100% {
    background-position: 0% 100vh;
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes background_movement {
  0% {
    background-position: 0% 0%;
  } 
  40% {
    background-position: 0% 100%;
  }
  75% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }

}

@media (max-width: 1450px) {
  #festival_page .presentation .festival_name {
    font-size: 80px;
  }
}

@media (max-width: 1199px) {
  #festival_page .presentation .festival_name {
    font-size: 15vw;
    line-height: 15vw;
  }

  #festival_page .scrollable_program::-webkit-scrollbar-thumb {
    background: var(--festival-second-color);
    display: none;
  }

  #festival_page .scrollable_program {
    height: calc(90vh - 124px);
    overflow-y: scroll;
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 630px) {
  #festival_page .presentation .logo {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #festival_page .presentation .logo svg {
    width: 10vw;
  }

  #festival_page .presentation .carousel_container .carousel_item img {
    width: calc(10vw + 50px);
  }

  #festival_page .program {
    padding: 0;
  }
}