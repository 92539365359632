.news_card {
  width: 200px;
  word-break: break-word;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.5);
  position: relative;
  display: grid;
  place-content: end;
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 10px;
  justify-content: left;
  margin: 5px;
}

.news_card h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.news_card .news_card_wallpaper{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.news_card .black_layer {
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.news_card .news_card_title {
  z-index: 2;
  position: relative;
  font-weight: 700;
}

.news_card.selected {
  border: 4px solid var(--dark_blue);
}

@media screen and (max-width: 768px) {

}