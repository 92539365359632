#mouse_dot {
    width: 30px;
    height: 30px;
    background-color: var(--dark_blue);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.4);
    position: absolute;
    z-index: 14001;
    border-radius: 100%;
    display: grid;
    place-content: center;
}

#mouse_dot .icon {
    width: 40px;
    height: 40px;
}