::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

#newsletter_page {
  background-color: #3E6CA4;
}

#newsletter_page .subscribe_button {
  background-color: transparent;
  border: 1px solid white;
  font-weight: 300;
}

#newsletter_page .subscribe_button {
  text-decoration: none;
}

#newsletter_page .home_button {
  font-weight: 300;
}

#newsletter_page .home_button:hover {
  text-decoration: underline;
}

#newsletter_page input:-webkit-autofill,
#newsletter_page input:-webkit-autofill:active,
#newsletter_page input:-webkit-autofill:focus,
#newsletter_page input:-webkit-autofill:hover {
  -webkit-text-fill-color: white !important;
  -webkit-background-clip: text;
}

#newsletter_page #mc_embed_shell{
  display: none;
}


#newsletter_page .main_title{
  color: white !important;
}

#newsletter_page .content {
  color: white !important;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

#newsletter_page button {
  box-shadow: none;
  color: white;
}

#newsletter_page .MuiInputAdornment-root,
#newsletter_page .MuiInputBase-input,
#newsletter_page .MuiFormLabel-root,
#newsletter_page .MuiFormHelperText-root {
  color: white;
}

#newsletter_page .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

@media screen and (max-width: 768px) {
  #newsletter_page .main_title{
    font-size: 40px;
  }

  #newsletter_page .description{
    font-size: 16px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}